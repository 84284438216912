
import {Component, Mixins, Prop} from 'vue-property-decorator';
import DynamicForm from '@/components/Forms/DynamicForm.vue';
import FormMixin from "@/components/Mixin/FormMixin";
import {State} from "vuex-class";
import store from "@/store";
import ViewModel from "@/components/Views/ViewModel";
import Permission from "@/common/helpers/Permission";

@Component({
    components: {}
})

export default class DynamicView extends Mixins(DynamicForm, FormMixin) {
  // eslint-disable-next-line
  // @ts-ignore: Unreachable code error
  @State("portalViewHidden") portalViewHidden;

  @Prop(String) ID?: any = null;
  @Prop(String) viewName?: any = null;
  @Prop(String) dynModuleName?: any = null;

  model = new ViewModel(this.viewName, this.dynModuleName);

  get showFormSettingLoaded() {
    return Boolean(this.portalViewHidden);
  }

  get hiddenParts() {
    return this.portalViewHidden;
  }

  // eslint-disable-next-line
  // @ts-ignore: Unreachable code error
  setPortalLocalDefaultFields(data) {
    if (Object.prototype.hasOwnProperty.call(data,'account')) {
      const currentAccount = store.state.currentAccount;
      data.account = currentAccount;
      
    }
    return null;
  }

  // eslint-disable-next-line
  // @ts-ignore: Unreachable code error
  checkPermission(module, action, msg = false) {
    return Permission.module(module, action, msg);
  }

  checkAccountPermission() {
    return true;
  }

}

